// Generated by Framer (b9ee990)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Mf_URkT6Z", "hWUuuI5w9"];

const variantClassNames = {hWUuuI5w9: "framer-v-f61j9b", Mf_URkT6Z: "framer-v-mzeuog"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Black: "Mf_URkT6Z", White: "hWUuuI5w9"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Mf_URkT6Z", tap: KhazWuCxn, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Mf_URkT6Z", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaphhngzy = activeVariantCallback(async (...args) => {
if (KhazWuCxn) {
const res = await KhazWuCxn(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YPIF5", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-mzeuog", className)} data-framer-name={"Black"} data-highlight layoutDependency={layoutDependency} layoutId={"Mf_URkT6Z"} onTap={onTaphhngzy} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({hWUuuI5w9: {"data-framer-name": "White"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 13.5, intrinsicWidth: 59, pixelHeight: 27, pixelWidth: 118, src: new URL("assets/X8vTqMuZl9rsvfNCr2goKlHE.png", import.meta.url).href}} className={"framer-1tpga8w"} data-framer-name={"logo"} layoutDependency={layoutDependency} layoutId={"LVp3nMiEH"} transition={transition} {...addPropertyOverrides({hWUuuI5w9: {background: {alt: "", fit: "fill", intrinsicHeight: 30, intrinsicWidth: 131, pixelHeight: 30, pixelWidth: 131, src: new URL("assets/A6uCbOzT4YRMTrW0TdFPmMr2XgM.png", import.meta.url).href}}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YPIF5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YPIF5 .framer-g020vd { display: block; }", ".framer-YPIF5 .framer-mzeuog { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-YPIF5 .framer-1tpga8w { aspect-ratio: 4.37037037037037 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 14px); overflow: visible; position: relative; width: 61px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-YPIF5 .framer-mzeuog { gap: 0px; } .framer-YPIF5 .framer-mzeuog > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-YPIF5 .framer-mzeuog > :first-child { margin-top: 0px; } .framer-YPIF5 .framer-mzeuog > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 61
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"hWUuuI5w9":{"layout":["auto","auto"]}}}
 * @framerVariables {"KhazWuCxn":"tap"}
 */
const FramerQEAnuPBCo: React.ComponentType<Props> = withCSS(Component, css, "framer-YPIF5") as typeof Component;
export default FramerQEAnuPBCo;

FramerQEAnuPBCo.displayName = "Elemental/ Logo";

FramerQEAnuPBCo.defaultProps = {height: 14, width: 61};

addPropertyControls(FramerQEAnuPBCo, {variant: {options: ["Mf_URkT6Z", "hWUuuI5w9"], optionTitles: ["Black", "White"], title: "Variant", type: ControlType.Enum}, KhazWuCxn: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerQEAnuPBCo, [])